<div class="contact-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <p>Feel free to drop us a message with any queries you have. We’re here to help!</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <h1>Contact Us</h1>
            <div class="row" style="padding-top: 20px;">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <!-- <h3>We’d love to hear from you! Send us a message or give us a call.
                        </h3>
                        <div class="col-lg-12 col-md-12" style="margin: 20px 0;">
                            <button class="default-btn">CONTACT SALES<span></span></button>
                        </div> -->
                        <h3>What can we do for you?</h3>
                        <p>At our core, we prioritize customer satisfaction and it drives everything we do. Whether
                            you're an existing client, a prospective client, or seeking a role as an engineer, feel free
                            to reach out to us anytime with any questions or inquiries you may have!
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>We’d love to hear from you! Send us a message or give us a call.</h3>
                        <!-- <h3>Contact Us</h3> -->
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required
                                            placeholder="Your Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                            placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required
                                            class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required placeholder="Your Subject">
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                            required placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">
                                        Send Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <div style="margin-bottom: 30px;">
                        <div class="row">
                            <div class="col-lg-4 col-md-12">
                                <h3>Address</h3>
                                <!-- <h3>OKLAHOMA</h3> -->
                                <p>925 Summer Rain Way, Acworth, GA 30102</p>
                            </div>
                            <div class="col-lg-4 col-md-12">
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <h3>Contact</h3>
                                <h3>alphabyte insights LLC</h3>
                                <a>info&#64;alphabyte-insights.com</a>
                                <p>Phone: +1 678 701 4893</p>
                                <ul class="social">
                                    <!-- <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                                    <li><a href="https://www.linkedin.com/company/alphabyte-insights-llc/about/?viewAsMember=true"
                                            target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                                    <!-- <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>