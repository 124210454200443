<div class="work-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 style="text-align: center;">GAINES</h2>
            <p>Discovering Artificial Intelligence in
                Personal Vaults & Internal Systems
            </p>
        </div>
    </div>
</div>

<section class="features-area pt-100 pb-70 bg-f4f6fc" style="margin-bottom: 50px;">
    <div class="container">

        <div class="content-body">
            <!-- <h1>Table of Contents</h1>
            <div class="toc">
                <ul>
                    <li>01. Highlights</li>
                    <li>02. Partners & Collaborators</li>
                    <li>03. Research Analytics</li>
                    <li>04. Team Identities</li>
                </ul>
            </div> -->

            <!-- Introduction Section -->
            <div class="content-section">
                <h2 id="introduction">01. Highlights</h2>
                <div style="display: flex; gap: 10px;">
                    <div style="width: 50%;">
                        <p><strong>Discovering the AI in Personal Vaults and Internal Systems.</strong><br>
                            The Gaines Application represents a groundbreaking innovation in scientific computing and
                            generative
                            AI (GenAI) for enterprise ecosystems. It is designed to transform the way organizations
                            access,
                            analyze, and synthesize information by leveraging internal systems and personal vaults.
                            Originating
                            from the concept of addressing the limitations of current GenAI systems in enterprise
                            environments,
                            the Gaines Application was conceived to overcome the challenges of fragmented data sources,
                            limited
                            computational efficiency, and a lack of contextual relevance in generated outputs.</p>
                    </div>
                    <div style="width: 50%;">
                        <img src="assets/img/images/ai.jpg" alt="">
                    </div>
                </div>
                <p>Gaines is a scientific architecture that employs computational systems to self-integrate,
                    creating a
                    knowledge resource through deep learning techniques. These techniques enable it to
                    understand and
                    generate human-like text based on input. It can engage in conversations, answer questions,
                    provide
                    explanations, and assist in exchanging information.</p>
                <p>Gaines is built on the GPT (Generative Pre-trained Transformer) architecture, utilizes deep
                    learning
                    methods. Currently, it is pre-trained on self-integrated text data from various file formats
                    (.pdf,
                    .txt, .doc, .docx), enabling it to comprehend context and generate coherent, relevant
                    responses
                    across diverse topics.</p>
            </div>

            <!-- Problems & Solutions Section -->
            <div class="content-section partner-section">
                <h2 id="problems-solutions">02. Partners & Collaborators</h2>
                <div class="card-container">
                    <div class="partner-card">
                        <img src="assets/img/partners/acce.jpeg" alt="Accelotics Logo">
                        <p>Accelotics Technologies (OPC) Private Limited</p>
                    </div>
                    <div class="partner-card">
                        <img src="assets/img/partners/microsoft.png" alt="Microsoft Logo">
                        <p>Microsoft</p>
                    </div>
                    <div class="partner-card">
                        <img src="assets/img/partners/Amazon.png" alt="Amazon Logo">
                        <p>Amazon</p>
                    </div>
                </div>
            </div>

            <div class="content-section">
                <h2 id="problems-solutions">03. Research Analytics</h2>
                <div class="funding-section">
                    <h3>
                        Number of users analyzed in our market research
                    </h3>
                    <h3 style="font-size: 50px;">3,147,300,000</h3>
                    <div class="funding-amount">
                        <div>This analysis is by the Microsoft Analytics</div>
                    </div>
                </div>
            </div>

            <!-- <div class="content-section">
                <h2 id="problems-solutions">04. Team Identities</h2> -->
                <!-- <div class="funding-section">
                    <h3>
                        Number of users analyzed in our market research
                    </h3>
                    <h3 style="font-size: 50px;">3,147,300,000</h3>
                    <div class="funding-amount">
                        <div>This analysis is by the Microsoft Analytics</div>
                    </div>
                </div> -->


                <!-- <div class="content-section">
                <h2 id="problems-solutions">Core Developers</h2>
                <ul>
                    <li>Sai Swaraj Chenna</li>
                    <li>Bhavesh Asanabada</li>
                    <li>Srinath Atekuri</li>
                </ul>
            </div> -->

                <!-- <div class="developer-section">
                    <h2 id="problems-solutions">Core Developers</h2>
                    <div class="card-container">
                        <div class="developer-card">
                            <img src="assets/img/partners/user.jpg" alt="Sai Swaraj Chenna">
                            <p>Sai Swaraj Chenna</p>
                        </div>
                        <div class="developer-card">
                            <img src="assets/img/partners/user.jpg" alt="Bhavesh Asanabada">
                            <p>Bhavesh Asanabada</p>
                        </div>
                        <div class="developer-card">
                            <img src="assets/img/partners/user.jpg" alt="Srinath Atekuri">
                            <p>Srinath Atekuri</p>
                        </div>
                        <div class="developer-card">
                            <img src="assets/img/partners/user.jpg" alt="Kiran Sankuri">
                            <p>Kiran Sankuri</p>
                        </div>
                    </div>
                </div> -->


                <!-- <div class="">
                <h2 id="problems-solutions">Network Organization</h2>
                <ul>
                    <li>Deloitte</li>
                    <li>PwC</li>
                    <li>Research Scientists and Associates from
                        <ul>
                            <li>Indiana University</li>
                            <li>Georgia Tech University</li>
                            <li>Clerk University</li>
                            <li>University of Massachusetts - Boston</li>
                        </ul>
                    </li>
                    <li>Developers from GoDaddy, IBM, and Alumni of BITS Pilani.</li>
                </ul>
            </div> -->

                <!-- <div class="network-organization">
                    <h2 id="problems-solutions" style="color: #1A5276;">Network Organization</h2>
                    <ol>
                        <li>Deloitte</li>
                        <li>PwC</li>
                        <li>Research Scientists and Associates from
                            <ol type="a">
                                <li>Indiana University</li>
                                <li>Georgia Tech University</li>
                                <li>Clerk University</li>
                                <li>University of Massachusetts - Boston</li>
                            </ol>
                        </li>
                        <li>Developers from GoDaddy, IBM, and Alumni of BITS Pilani.</li>
                    </ol>
                </div>

            </div> -->

            <!-- <div class="content-section">
                <h2 id="problems-solutions">04. Team Identities</h2>
                <div class="funding-section">
                    <h3>
                        Number of users analyzed in our market research
                    </h3>
                    <h3 style="font-size: 50px;">3,147,300,000</h3>
                    <div class="funding-amount">
                        <div>This analysis is by the Microsoft Analytics</div>
                    </div>
                </div>
            </div> -->

            <!-- Unique Value Proposition Section -->
            <!-- <div class="content-section">
                <h2>Unique Value Proposition</h2>
                <ul class="value-prop-list">
                    <li>Self Integration Data Retrieval from Link</li>
                    <li>Multiformat Data Processing</li>
                    <li>Cross Domain Expertise</li>
                    <li>Sharable Knowledge Base</li>
                    <li>Deep Learning Based Text Understanding</li>
                </ul>
            </div> -->

            <!-- Market Opportunity Section -->
            <!-- <div class="market-section">
                <h3>Market Opportunity (By 2030)</h3>
                <div class="market-stats">
                    <div><strong>1.8 T</strong> - Artificial Intelligence</div>
                    <div><strong>1.5K B</strong> - Enterprise Knowledge Management</div>
                    <div><strong>132 B</strong> - Enterprise AI and Data Analytics</div>
                    <div><strong>19 B</strong> - Customer Support and Enterprise Chatbots</div>
                    <div><strong>3,147,300,000</strong> - Number of users analyzed in our market research – Microsoft
                        Analytics</div>
                </div>
            </div> -->

            <!-- <div class="market-container">
                <h1 class="market-title">Market Size (By 2030)</h1>
                <div class="market-content">

                    <div class="market-data">
                        <div class="market-item">
                            <div class="market-value">1.8 T</div>
                            <div>Artificial Intelligence</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">1.5K B</div>
                            <div>Enterprise Knowledge Management</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">132 B</div>
                            <div>Enterprise AI and Data Analytics</div>
                        </div>
                        <div class="market-item">
                            <div class="market-value">19 B</div>
                            <div>Customer Support and Enterprise Chatbots</div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div class="funding-section">
                <h3 style="font-size: 50px;">3,147,300,000</h3>
                <div class="funding-amount">
                    <div>Number of users analyzed in our
                        market research – Microsoft Analytics</div>
                </div>
            </div>

            <div class="funding-section">
                <h3>Research Analytics</h3>
                <h3>
                    Number of users analyzed in our market research
                </h3>
                <h3 style="font-size: 50px;">3,147,300,000</h3>
                <div class="funding-amount">
                    <div>This analysis is by the Microsoft Analytics</div>
                </div>
            </div> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let element of works"
                style="margin-bottom: 20px; display: flex; flex-wrap: wrap;">
                <div class="features-box-one" style="text-align: start;">
                    <img [src]="element.img" alt="" style="height: 250px; padding: 10px;">
                    <div class="content_section">
                        <h3 style="font-size: 20px;">{{ element.title }}</h3>
                        <p>{{ element.description }}</p>
                        <a class="read-more" [routerLink]="'/work/' + element.id">Read More <i
                                class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>